<template>
    <div class="container">
        <!-- 面包屑导航 -->
        <Breadcrumb :breadList="breadList"> </Breadcrumb>
        <a-result
            status="success"
            title="添加优惠券成功！"
            sub-title="您还可以进行下面的操作：去优惠券列表，或者继续添加新的优惠券！"
        >
            <template #extra>
                <a-button
                    key="console"
                    type="primary"
                    @click="$utils.linkTo('/shop/coupon/add')"
                > 继续添加优惠券 </a-button>
                <a-button key="buy"  @click="$utils.linkTo('/shop/coupon/list')"> 返回优惠券列表 </a-button>
            </template>
        </a-result>
    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
export default {
  components: {
    Breadcrumb
  },
  data () {
    return {
      breadList: [
        {
          name: '营销管理',
          url: ''
        },
        {
          name: '优惠券',
          changeParent: true,
          url: '/shop/coupon/list'
        },
        {
          name: '新建优惠券',
          url: ''
        }
      ]
    }
  },
  created () {},
  computed: {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.container {
    width: auto;
    min-width: 1000px;
}
</style>
